@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  /* font-family: 'Public Sans', sans-serif;
  font-family: 'Ubuntu', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #a2a2a2 inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}

/*  * SCROLL BAR STYLE */

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #99999981;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8e8e8e;
}

/*  * SCROLL BAR STYLE */

.ant-radio-inner {
  width: 14px !important;
  height: 14px !important;
}

.ant-radio-inner::after {
  width: 14px !important;
  height: 14px !important;
  font-size: 10px !important;
  margin-block-start: -7px !important;
  margin-inline-start: -7px !important;
}

.ant-radio-wrapper {
  font-size: 10px !important;
}

.ant-radio-wrapper span.ant-radio + * {
  padding-inline-start: 5px !important;
  padding-inline-end: 0px;
}

/* Memo section collapses */

.memo-collapse {
  font-size: 12px;
}

.memo-collapse .memo-collpase-panel {
  overflow: hidden;
  background: white;
  border: 1px solid #d9d9d9 !important;
  border-radius: 2px !important;
}

.memo-collapse .ant-collapse-content-box {
  border-top: 1px solid #d9d9d9 !important;
  padding: 5px 4px !important;
}

.memo-collapse .ant-collapse-header {
  padding: 8px !important;
}

.memo-collapse .ant-collapse-header .ant-collapse-arrow svg {
  width: 10px !important;
  height: 10px !important;
}

.send-popup .ant-popover-inner {
  border-radius: 2px !important;
}

.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*  main page overwrite */
.well-setting-tabs .ant-tabs-tabpane,
.well-setting-tabs .ant-tabs-content {
  height: 100% !important;
}

.well-setting-tabs .ant-tabs-nav {
  padding: 0;
  padding-left: 5px;
  margin: 0px;
  background: #fff;
  margin-bottom: 2px;
  border-radius: 4px;
}

.well-setting-tabs .ant-tabs-tab {
  padding: 4px 0;
}

.well-setting-tabs .ant-tabs-nav-list {
  padding: 0 10px;
  width: 100%;
  justify-content: space-evenly;
}

.ant-select-selector {
  border-radius: 2px !important;
}

/* setup project page */

.setup-steps .ant-steps-item-title {
  line-height: 20px;
  font-weight: 600;
}

/* .ant-select-selector  {
  height: 40px !important
} */

/* .min-select .ant-select-selector  {
  height: 32px !important
} */
/* settings page */

.settings-layout .ant-layout-sider-trigger {
  background: #24789d;
  bottom: 8px !important;
  border-radius: 0 0 4px 4px;
}

.settings-layout .ant-menu-item,
.settings-layout .ant-menu-submenu-title {
  column-gap: 8px;
}

.select-space :first-child {
  flex: 1;
}

#units-table .ant-table-body {
  max-height: 500px !important;
}

.ant-table-filter-dropdown-btns .ant-btn-primary {
  background-color: #24789d !important;
}

.ant-table-filter-dropdown-btns .ant-btn-primary:hover {
  background-color: #24799d8b !important;
}

/* .ant-table-content table {
  border-radius: 2px 2px 0 0 !important;
}

.ant-table-cell:last-child  {
  border-radius: 0 2px 0 0 !important;
}

.ant-table-cell:first-child {
  border-radius: 2px 0 0 0 !important;
} */

.ant-table-wrapper table {
  border-radius: 2px !important;
}

.ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child,
.ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:last-child {
  border-radius: 0px !important;
}

.chartModeBtns span {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.ant-picker-ok button {
  background: #24789d;
  border-color: #24789d;
  color: #fff;
  box-shadow: none;
}

.ant-picker-ok button:hover {
  border-color: #5da1b0;
}

.ant-table-wrapper .ant-table-container {
  border-start-start-radius: 2px;
  border-start-end-radius: 2px;
}

.show-live-message .ant-notification-notice-description {
  margin-inline-start: 28px !important;
}

.show-live-message .ant-notification-notice-close {
  top: 7px !important;
  inset-inline-end: 7px !important;
}

.export-mode-radio .ant-radio-wrapper {
  font-size: 12px !important;
}

.delete-pipe-modal .ant-modal-content {
  border-radius: 2px;
}

.custom-modal .ant-modal-content {
  border-radius: 2px;
}

.add-category-select .ant-space-item {
  width: 100%;
}

.dark-mode-toggle .ant-switch-inner-checked {
  margin-top: 4px;
}

.dark-mode-toggle .ant-switch-inner-unchecked {
  margin-top: -15px !important;
  padding-left: 2px;
}

.settings-sidebar .ant-menu-inline-collapsed .ant-menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
}

.settings-sidebar .ant-menu-inline-collapsed .ant-menu-item span {
  display: none;
}

.settings-sidebar .ant-menu-inline-collapsed .ant-menu-submenu-title {
  display: flex;
  justify-content: center;
  width: fit-content;
  align-items: center;
}

.settings-sidebar .ant-menu-inline-collapsed .ant-menu-submenu-title {
  display: flex;
  justify-content: center;
  width: fit-content;
  align-items: center;
}

.settings-sidebar .ant-menu-inline-collapsed .ant-menu-submenu-title span {
  display: none;
}

.casing-tool-table td {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.antd-dnd .ant-select-selector {
  height: 30px !important;
  padding: 0 2px !important;
}

.ant-table-column-title {
  text-transform: capitalize;
}

.ant-dropdown-menu-submenu-title {
  display: flex !important;
}

.flash-animation {
  width: 100px;
  height: 100px;
  border-color: red;
  animation: flash 0.5s infinite;
  z-index: 0;
}

.flash-animation-bang {
  border-color: red;
  animation: flash 0.5s infinite;
  z-index: 0;
}

.costume-wrapp-shadow {
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flashBorder {
  0% {
    border-color: red;
  }
  50% {
    border-color: transparent;
  }
  50% {
    border-color: red;
  }
}

.flashing-border {
  border: 8px solid;
  animation: flashBorder 0.5s infinite;
}

.ant-modal-mask, .ant-modal-wrap {
  z-index: 500 !important;
}

.ant-modal {
  z-index: 150 !important;
}

/* Light mode styles */
.custom-modal-confirm .ant-modal-header {
  background-color: #3c8ca3;
  color: #ffffff !important;
}

.custom-modal-confirm .ant-modal-content {
  background-color: #ffffff;
}

.custom-modal-confirm .ant-modal-footer {
  background-color: #ffffff;
}

.custom-modal-confirm .ant-btn-primary {
  background-color: #3c8ca3;
  border-color: #3c8ca3;
  color: #ffffff !important;
}

.custom-modal-confirm .ant-btn-primary:hover {
  background-color: #367a8b;
  border-color: #367a8b;
}

/* Dark mode styles */
.custom-modal-confirm-dark-mode .ant-modal-header {
  background-color: #252525;
  color: #ffffff !important;
}

.custom-modal-confirm-dark-mode .ant-modal-content,
.custom-modal-confirm-dark-mode .ant-modal-content .ant-modal-confirm-content,
.custom-modal-confirm-dark-mode .ant-modal-content .ant-modal-confirm-title {
  background-color: #333333;
  color: #ffffff !important;
}

.custom-modal-confirm-dark-mode .ant-modal-footer {
  background-color: #333333;
  color: #ffffff !important;
}

.custom-modal-confirm-dark-mode .ant-btn-primary {
  background-color: #367a8b;
  border-color: #367a8b;
  color: #ffffff !important;
}

.custom-modal-confirm-dark-mode .ant-btn-primary:hover {
  background-color: #1f1f1f;
  border-color: #1f1f1f;
  color: #ffffff !important;
}

.ant-popover-inner-content {
  white-space: nowrap;
}

.antd-drawer-menu li div {
  padding-left: 0 !important;
  margin-top: -5px !important;
}

.antd-drawer-menu li ul li {
  padding-left: 20px !important;
}

.custom-row {
  padding: 2px !important; /* Reducing padding for rows */
  margin: 0 !important; /* Removing margins */
}

.ant-table-cell {
  padding: 2px !important; /* Reducing padding inside table cells */
}

.ant-table-tbody > tr > td {
  padding: 2px !important; /* Ensuring table body cells also have reduced padding */
}

.ant-checkbox-wrapper {
  margin: 0 !important; /* Reducing margin around checkboxes */
}

.ant-table-body {
  font-size: 12px; /* Adjust font size if needed */
}

.parameter-unit-changer .ant-select-selector {
  border: 1px solid rgba(179, 179, 179, 0.2) !important;
  border-radius: 4px !important;
}

.custom-table .ant-table-thead > tr > th {
  padding: 8px; /* Adjust header padding */
  font-size: 12px; /* Reduce font size for headers */
}

.custom-table .ant-table-tbody > tr > td {
  padding: 6px; /* Adjust cell padding */
  font-size: 12px; /* Reduce font size for cells */
}

.custom-table .ant-table {
  border-collapse: collapse; /* Reduce spacing between table elements */
}

.custom-table {
  font-size: 12px; /* Global font size for the table */
}

.custom-table .ant-table-row {
  height: auto; /* Allow rows to adjust based on content */
}

.custom-table .ant-table-body {
  overflow-y: scroll !important; /* Ensure vertical scroll */
  max-height: 300px; /* Match the scroll setting in the table */
}

.custom-table .ant-table-wrapper {
  overflow-x: auto; /* Enable horizontal scroll */
}
.ant-picker-cell.ant-picker-cell-hover.ant-picker-cell-selected.ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner {
  color: #ffffff !important;
  background: #959595 !important;
}
.ant-picker-cell.ant-picker-cell-hover.ant-picker-cell-selected.ant-picker-cell-in-view .ant-picker-cell-inner {
  color: #ffffff !important;
  background: #24789d !important;
}
.ant-picker-ok button:hover {
  background: #24789d !important ;
}
